<template>
  <div>
    <div style="height: 100vh">
      <!-- 表单区 -->
      <div class="box"></div>
      <div class="top">信息采集平台</div>
      <div class="login_container">
        <div class="login_view">
          <span class="login_lable">账号：</span>
          <input
            class="login_text"
            placeholder="请输入登录账号"
            bindinput="listenerUsernameInput"
          />
        </div>
        <div class="login_view">
          <span class="login_lable">密码：</span>
          <input
            class="login_text"
            placeholder="请输入密码"
            password="true"
            bindinput="listenerPasswordInput"
          />
        </div>

        <div>
          <button class="login_button" @click="loginAction">登 录</button>
        </div>
        <div style="position: relative">
          <div class="login_token" bindtap="ifToken">登录保存账号</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
import { CheckUser } from "@/api/dayao";
export default {
  data() {
    return {
      dataFrom: {
        uname: "",
        upwd: "",
      },
    };
  },
  methods: {
    //   登录
    loginAction() {
      CheckUser(this.dataFrom).then((res)=>{
          console.log(res);
      });
    },
  },
};
</script>
<style scoped>
.box {
  height: 300rpx;
  width: 100%;
}
.login-icon {
  flex: none;
}
.top {
  font-size: larger;
  text-align: center;
  margin: 30px 0px;
}
.login-img {
  width: 750rpx;
  height: 300rpx;
}
.login_container {
  margin: 40px 10px;
}

.login_view {
  width: calc(100% - 40px);
  padding: 0 20px;
  margin-bottom: 20px;
  display: flex;
  justify-items: center;
  align-items: center;
}

.login_text {
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  border: 1px solid black;
  padding: 0 12px;
  width: calc(100% - 70px);
  border-radius: 4px;
  /* margin-top: -45px;
  margin-left: 40px; */
  color: black;
}

.login_lable {
  color: #000;
  font-size: 14px;
  width: 50px;
}

.login_button {
  width: 90%;
  height: 45px;
  line-height: 45px;
  background: #5681f2;
  color: #fff;
  border: 0px;
  margin: 15px 5%;
}
.login_token {
  position: absolute;
  right: 30px;
  font-size: 14px;
  color: #00b26a;
}
</style>