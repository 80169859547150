import request from '@/utils/request'
export function SearchReport (query) {
    return request({
      url: 'CheckRec/SearchReport',
      method: 'get',
      params: query
    })
  }
  export function WxExportReportToPdf (query) {
    return request({
      url: 'CheckRec/WxExportReportToPdf',
      method: 'get',
      params: query
    })
  }
  export function GetMyCollecAreaList (query) {
    return request({
      url: 'IArea/GetMyCollecAreaList',
      method: 'get',
      params: query
    })
  }
  export function CheckUser (data) {
    return request({
      url: 'Plat/CheckUser',
      method: 'post',
      data: data
    })
  }